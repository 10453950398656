@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular.eot');
    src: local('Roboto'), local('Roboto-Regular'),
        url('Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Regular.woff2') format('woff2'),
        url('Roboto-Regular.woff') format('woff'),
        url('Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Bold.woff2') format('woff2'),
        url('Roboto-Bold.woff') format('woff'),
        url('Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

