@import '../public/static/fonts/roboto/roboto.css';

html,
body,
#root {
  width: 100%;
  height: 100%;
  position: relative;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vh;
}
